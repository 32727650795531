import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { MOBILE_RESPONSIVE, TABLET_RESPONSIVE } from "../../../../constants/responsive.constants";
import s from "./BaseLmtSalesSummaryChart.module.css";
// import t from ".";

export interface LmtSalesTopCustomerComponentProps {
    chartData: any | null;
    handleChangeCustomerTab: (customer: string, country: string) => void;
    country: string
}

export default function LmtSalesTopCustomerComponentProps({ chartData, handleChangeCustomerTab, country }: LmtSalesTopCustomerComponentProps) {
    useEffect(() => {
        // Create chart instance
        const chart = am4core.create('topCustomer', am4charts.XYChart);

        // // Set chart orientation to vertical
        // chart.leftAxesContainer.layout = 'vertical';

        // Add data
        if (Array.isArray(chartData)) {
            chart.data = chartData.reverse();
        }

        //remove  logo
        chart.logo.disabled = true;

        // remove zoomOutButton
        chart.zoomOutButton.icon.disabled = true;
        chart.zoomOutButton.background.fill = am4core.color("#1D1D1B66");

        // Create category axis (vertical)
        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.minGridDistance = 1;
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.inversed = true; // Invert the category axis to display categories from top to bottom
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.renderer.labels.template.fontWeight = 'lighter';
        categoryAxis.renderer.labels.template.fill = am4core.color('#C2C2C2');
        categoryAxis.renderer.grid.template.disabled = true; // Disable grid lines
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.8;
        categoryAxis.cursorTooltipEnabled = true;
        if (categoryAxis.tooltip) {
            categoryAxis.tooltip.label.fontSize = 14; // Set the desired font size
        }
        // categoryAxis.scale = 1;

        // Create value axis (horizontal)
        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fontWeight = 'lighter';
        valueAxis.renderer.labels.template.fill = am4core.color('#C2C2C2');
        valueAxis.renderer.grid.template.disabled = true; // Disable grid lines
        valueAxis.cursorTooltipEnabled = false;

        // Create series for the stacked bar chart
        const series = chart.series.push(new am4charts.ColumnSeries());
        
        // series.columns.template.events.on("over", function (event) {
        //     chart.cursor.fullWidthLineX = true;
        //     chart.cursor.lineX.strokeOpacity = 0;
        //     chart.cursor.lineX.fillOpacity = 0.1;
        //     document.body.style.cursor = "pointer";
        // });
        
        // series.columns.template.events.on("out", function (event) {
        //     chart.cursor.fullWidthLineX = false;
        //     document.body.style.cursor = "default";
        // });
        
        series.dataFields.valueX = 'value';
        series.dataFields.categoryY = 'category';
        series.name = 'Series 1';
        series.columns.template.tooltipText = '{categoryY}: [bold]{valueX}[/]';
        if (!series.columns.template.tooltip) {
            series.columns.template.tooltip = new am4core.Tooltip();
        }
        series.columns.template.tooltip.label.fontSize = 14;
        series.stacked = true;
        series.cursorTooltipEnabled = false;
        series.columns.template.events.on("hit", function (ev) {
            if (ev.target.dataItem?.categories?.categoryY) {
                handleChangeCustomerTab(ev.target.dataItem?.categories?.categoryY, country);
            }
        });

        // Customize column height based on data
        series.columns.template.height = 24;

        // Set custom colors for columns
        series.columns.template.fill = am4core.color('#00D1CA');

        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        if (chartData?.length > 5) {
            // Add scrollbar on Y axes
            const scrollbarY = new am4core.Scrollbar();
            chart.scrollbarY = scrollbarY;
            scrollbarY.showSystemTooltip = true;
            scrollbarY.showSystemTooltip = true;
            scrollbarY.startGrip.disabled = true; // Disable resizing the start grip of scroll bar
            scrollbarY.endGrip.disabled = true; // Disable resizing the end grip of scroll bar
            // Set the scrollbar's parent to the bottomAxesContainer
            if (chart.scrollbarX) {
                // Set the scrollbar's parent to the bottomAxesContainer
                chart.scrollbarX.parent = chart.bottomAxesContainer;
            }

            // Move the scroll position to the top
            chart.scrollbarY.start = 1;
        }


        // Fix the zoom size by setting minZoomCount and maxZoomCount
        categoryAxis.minZoomCount = 5; // Prevent zooming out
        categoryAxis.maxZoomCount = 5; // Prevent zooming in

        return () => {
            chart.dispose();
        };
    }, [chartData]);

    return (
        <>
            {chartData && chartData.length > 0 ?
                (
                    <div className={s.saleVolumeChartContainer}>
                        <div id="topCustomer" className={s.salesChart}>
                        </div>
                    </div>
                ) :
                (
                    <div className={s.saleVolumeChartNotAvailableContainer}>
                        <span className={s.chartNotAvailable}>Chart not available</span>
                    </div>
                )
            }
        </>
    );
};
