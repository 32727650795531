import { Fragment } from 'react';
import parse from 'html-react-parser';


export const formatUnit = (unit) => {
    const regexUnit = new RegExp('(m3)')
    if (regexUnit.test(unit)) {
      unit = unit.replace(regexUnit, "m<sup>3</sup>")
    }
    return <Fragment><span>{parse(unit)}</span></Fragment>;
  };