import { notification, Popover, Radio, RadioChangeEvent, Space, Tooltip } from "antd";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { setShowFP } from "../../action/actionForwardPrice";
import { setSelectedPersona, setSelectedPersonaViewUrl } from "../../action/actionUsers";
import { signOut } from "../../AuthService";
import { URL_SIDE_BAR } from "../../constants/map.constants";
import { URL_NAV_BAR_CONFIG } from "../../constants/sidebar.constants";
import { SESSION_STORAGE_KEY, USER_NAME_MAPPING } from "../../constants/user.constants";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import { isPageAdmin, checkActiveTab, isActiveTab } from "../../utils/Url.utils";
import { getSustainabilityMap, isUserAdmin } from "../../utils/user.utils";
import { getDefaultMap, getDefaultView, isSuperAdmin, userRoutes } from "../../utils/user.utils";
import { fetchMapData, isOpenSideBarMobile, updateActiveTab } from "../../action/actionMap";
import ModalForwardPriceComponent from "../ForwardPrice/Modal/ModalForwardPriceComponent";
import s from "./navigation.module.css";
import { fetchDisableCallOutSustainability, fetchGhgData } from "../../action/actionSustainability";

function NavigationComponent() {
  const history = useHistory();
  const pathName = window.location.pathname;
  const dispatch = useDispatch();
  const [image, setImage] = useState("/img/avatar.png");
  const [displayName, setName] = useState("Go User");
  const [buttonClick, setButton] = useState(false);
  const [isPopUser, setIsPopUser] = useState(false);
  const [openPopover, setOpenPopover] = useState<string | null>(null);
  const [isPopPersona, setIsPopPersona] = useState(false);
  const imageAvatar = useSelector((state) => get(state, "user.image"));
  const userProperties = useSelector((state) =>
    get(state, "user.user_properties")
  );

  const personaSelection = useSelector((state) =>
    get(state, "user.personaSelection")
  );

  const selectedPersona = useSelector((state) =>
    get(state, "user.selectedPersona")
  );

  const selectedPersonaViewUrl = useSelector((state) =>
    get(state, "user.selectedPersonaViewUrl")
  );

  useEffect(() => {
    if (selectedPersona === "") {
      const selectedPersonaSession = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY.SELECTED_PERSONA) as string)
      dispatch(setSelectedPersonaViewUrl(selectedPersonaSession?.viewUrl))
      if (selectedPersonaSession) {
        dispatch(setSelectedPersona(selectedPersonaSession.opuName));
      } else {
        personaSelection.forEach(item => {
          if (item.isDefault) {
            dispatch(setSelectedPersona(item.opuName))
            sessionStorage.setItem(SESSION_STORAGE_KEY.SELECTED_PERSONA, JSON.stringify(item));
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (imageAvatar) {
      const reader = new FileReader();
      reader.readAsDataURL(imageAvatar);
      reader.onload = () => storeImage(reader.result as string);
    }
  }, [imageAvatar]);

  useEffect(() => {
    let userName: string = "";
    if (userProperties) {
      const domainBankPattern = /pethlab|pethstag|dev.petronas|stag.petronas/g;
      if (userProperties.mail.toLowerCase().match(domainBankPattern)) {
        const userNameMapping = USER_NAME_MAPPING;
        userName =
          userNameMapping.get(userProperties.mail.toLowerCase()) || "Go User";
      } else {
        userName = formatName(userProperties.displayName);
      }
      setName(userName);
    }
  }, [userProperties]);

  function formatName(name: string) {
    const charIndex: number = name.indexOf("(", 0);
    return name.substring(0, charIndex - 1);
  }

  function storeImage(imageString: string) {
    if (!sessionStorage.getItem(SESSION_STORAGE_KEY.USER_IMAGE)) {
      sessionStorage.setItem(SESSION_STORAGE_KEY.USER_IMAGE, imageString);
    }
    setImage(imageString);
  }

  function clickButton() {
    setButton(!buttonClick);
  }

  function setPopoverFunc(link) {
    setIsPopUser(false);
  
    if (link.includes('admin')) {
      dispatch(updateActiveTab(URL_SIDE_BAR.USERMANAGEMENT));
    } else {
      dispatch(updateActiveTab(URL_SIDE_BAR.RISK_PROFILE));
    }
  
    history.push(link);
  }

  function _renderUsermangementContent() {
    return (
      <div className={s.popoverContent}>
        <div
          onClick={() => {
            setPopoverFunc(URL_SIDE_BAR.USERMANAGEMENT);
            setOpenPopover(null);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopoverFunc(URL_SIDE_BAR.USERMANAGEMENT);
              setOpenPopover(null);
            }
          }}
          tabIndex={0}
        >
          <img src='/img/icon/usermanagements.svg' alt='usermanagement' />
          <span>User Management</span>
        </div>
        <div
          onClick={() => {
            setPopoverFunc(URL_SIDE_BAR.LIMITCONFIGURATION);
            setOpenPopover(null);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopoverFunc(URL_SIDE_BAR.LIMITCONFIGURATION);
              setOpenPopover(null);
            }
          }}
          tabIndex={0}
        >
          <img src='/img/icon/limitconfigs.svg' alt='configuration' />
          <span>Limit Configuration</span>
        </div>
        <div
          onClick={() => {
            setPopoverFunc(URL_SIDE_BAR.SCORECARDORDERING);
            setOpenPopover(null);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopoverFunc(URL_SIDE_BAR.SCORECARDORDERING);
              setOpenPopover(null);
            }
          }}
          tabIndex={0}
        >
          <img src='/img/icon/scorecardorderings.svg' alt='scorecardordering' />
          <span>Scorecard Ordering</span>
        </div>
        <div
          onClick={() => {
            setPopoverFunc(URL_SIDE_BAR.DATA_HEALTH_DASHBOARD);
            setOpenPopover(null);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopoverFunc(URL_SIDE_BAR.DATA_HEALTH_DASHBOARD);
              setOpenPopover(null);
            }
          }}
          tabIndex={0}
        >
          <img src='/img/icon/datahealths.svg' alt='datahealth' />
          <span>Data Health Dashboard</span>
        </div>
        <div
          onClick={() => {
            setPopoverFunc(URL_SIDE_BAR.SCORECARD_MANAGEMENT);
            setOpenPopover(null);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopoverFunc(URL_SIDE_BAR.SCORECARD_MANAGEMENT);
              setOpenPopover(null);
            }
          }}
          tabIndex={0}
        >
          <img
            src='/img/icon/ScorecardManagement_grey.svg'
            alt='scoremanagement'
            style={{ opacity: '0.6', width: '18px', marginLeft: '5px' }}
          />
          <span>Scorecard Management</span>
        </div>
      </div>
    );
  }
  
  function _renderRiskContent() {
    return (
      <div className={s.popoverContent}>
        <div
          onClick={() => {
            setPopoverFunc(URL_SIDE_BAR.RISK_PROFILE);
            setOpenPopover(null);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopoverFunc(URL_SIDE_BAR.RISK_PROFILE);
              setOpenPopover(null);
            }
          }}
          tabIndex={0}
        >
          <img src='/img/icon/riskProfile.svg' alt='riskprofile' />
          <span>Risk Profile</span>
        </div>
      </div>
    );
  }
  

  function _renderPopUserManagement() {
    return (
      <Popover
        overlayClassName='popHeader'
        placement='bottom'
        content={_renderUsermangementContent()}
        visible={openPopover === 'userManagement'}
        trigger='click'
        onVisibleChange={(visible) => {
          setIsPopUser(visible ? !isPopUser : false);
          setOpenPopover(visible ? 'userManagement' : null);
        }}
      >
        <div className={s.tbodyText}>
          {isPageAdmin() || openPopover === 'userManagement' ? (
            <div className={s.navItem}>
              <img
                className={s.adminIcon}
                src='/img/icon/Admin-green.svg'
                alt='usermanagement'
              />
              <span className={`${s.subText} ${s.selected}`}>Admin</span>
            </div>
          ) : (
            <div className={s.navItem}>
              <img
                className={s.adminIcon}
                src='/img/icon/Admin-grey.svg'
                alt='usermanagement'
              />
              <span className={s.subText}>Admin</span>
            </div>
          )}
        </div>
      </Popover>
    );
  }

  function _renderRisk() {
    return (
      <Popover
        overlayClassName='popHeader'
        placement='bottom'
        content={_renderRiskContent()}
        visible={openPopover === 'riskManagement'}
        trigger='click'
        onVisibleChange={(visible) => {
          setIsPopUser(visible ? !isPopUser : false);
          setOpenPopover(visible ? 'riskManagement' : null);
        }}
      >
        <div className={s.tbodyText}>
          {(isActiveTab(URL_SIDE_BAR.RISK_PROFILE) || openPopover === 'riskManagement') ? (
            <div className={`${s.navItem} ${isActiveTab(URL_SIDE_BAR.RISK_PROFILE) ? s.active:''} ${s.navItem}`}>
              <img
                className={s.adminIcon}
                src='/img/icon/laptop-warning-active.svg'
                alt='riskmanagement'
                style={{ padding: '1px' }}
              />
              <span className={`${s.subText} ${s.selected}`}>Risk Management</span>
            </div>
          ) : (
            <div className={s.navItem}>
              <img
                className={s.adminIcon}
                src='/img/icon/laptop-warning.svg'
                alt='riskmanagement'
                style={{ padding: '1px' }}
              />
              <span className={s.subText}>Risk Management</span>
            </div>
          )}
        </div>
      </Popover>
    );
  }

  function _renderNavigationMobileTablet() {
    return (
      <>
        <div>
          <img
            id="profile-button"
            className={s.avatarMoblie}
            src={image}
            onClick={() => clickButton()}
            alt="profile"
          />
        </div>
        <span className={s.textMoblie}>Welcome back,</span>
        <div className={s.textNameMobile}>{displayName}</div>
      </>
    );
  }

  function onSelectPersona(e: RadioChangeEvent) {
    const value = e.target.value;
    const splitPathnameArr = pathName.split("/");
    personaSelection.forEach((item) => {
      if (item?.opuName === value) {
        if (item?.viewUrl[splitPathnameArr[splitPathnameArr.length - 1]] !== "") {
          history.push(item?.viewUrl[splitPathnameArr[splitPathnameArr.length - 1]]);
          sessionStorage.setItem(SESSION_STORAGE_KEY.SELECTED_PERSONA, JSON.stringify(item))
          dispatch(setSelectedPersonaViewUrl(item?.viewUrl))
          dispatch(setSelectedPersona(value));
        } else {
          notification.open({
            message: 'Info',
            duration: 8,
            className: 'personaNotification',
            icon: <i className="mdi mdi-information" />,
            description:
              `You don't have the access.`
          });
        }

      }
    });
  }

  function _renderPersonaContent() {
    return (
      <div className={s.popoverPersona}>
        <div>
          {/* {personaSelection.length > 1 && (
            <>
              <div>
                <p>PERSONA SELECTION</p>
              </div>
              <Radio.Group
                onChange={(e) => onSelectPersona(e)}
                value={selectedPersona}
              >
                <Space direction='vertical'>
                  {personaSelection.map((value) => (
                    <Radio value={value?.opuName}>{value?.opuName}</Radio>
                  ))}
                </Space>
              </Radio.Group>
              <div className={s.divBorder} />
            </>
          )} */}
          <div onClick={() => signOut()}>
            <img src='/img/icon/Logout.svg' alt='React' />
            <p>LOGOUT</p>
          </div>
        </div>
      </div>
    );
  }

  function _renderProfileBtn() {
    return (
      <Popover
        overlayClassName='popHeader'
        placement='bottom'
        trigger='click'
        visible={isPopPersona}
        content={_renderPersonaContent()}
        onVisibleChange={() => setIsPopPersona(!isPopPersona)}
      >
        <div className={`${s.navItem} ${isPopPersona && s.selected}`}>
          <img
            className={s.icon}
            src={isPopPersona ? '/img/icon/Profile-active.svg' : '/img/icon/Profile.svg'}
            onClick={() => clickButton()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {clickButton();}
            }}
            tabIndex={0}
            alt='profile'
          />
          <span className={s.subText}>Profile</span>
        </div>
      </Popover>
    );
  }


  function setIsShowFPModal() {
    dispatch(setShowFP(true));
  }

  function isPersonaAccessible(path: string) {
    const pathArr = path.split("/");
    const getPath = pathArr[pathArr.length - 1];
    let returnIsAccess = false;
    personaSelection.forEach((item) => {
      if (item.opuName === selectedPersona) {
        returnIsAccess = item.viewUrl[getPath] !== "";
      }
    });
    return returnIsAccess;
  }

  function getDefaultMapLink(defaultMap) {
    const { locationId, isDefault, level } = defaultMap;
    return `/map?location=${locationId}&satellite=${isDefault}&level=${level}`
  }

  function getDefaultGhgLink(defaultMap) {
    const { locationId, level } = defaultMap;
    return `/map?location=${locationId}&level=${level}`
  }


  function handleClickSidebar(config: any) {
    sessionStorage.setItem("preUrl", pathName);
    if (config.path.indexOf("map") > 0) {
      const defaultMap = getDefaultMap();
      const defaultMapLink = getDefaultMapLink(defaultMap);
      history.push(defaultMapLink)
      dispatch(fetchMapData(defaultMap.locationId));
      dispatch(fetchDisableCallOutSustainability())
    }
    else if (config.path.indexOf("sustainability") > 0) {
      const defaultMap = getSustainabilityMap();
      const defaultMapLink = getDefaultGhgLink(defaultMap);
      history.push(defaultMapLink)
      dispatch(fetchGhgData(defaultMap.locationId));
      dispatch(fetchDisableCallOutSustainability())
    }
    else {
      const defaultLink =
        getDefaultView(config.path, config.viewType, selectedPersonaViewUrl) || config.path;
      dispatch(fetchDisableCallOutSustainability())
      history.push(defaultLink);
    }
  }

  function handleActiveTabe(tab: string) {
    dispatch(updateActiveTab(tab));
  }

  const handleCloseSideBar = (isOpen: boolean) => {
    dispatch(isOpenSideBarMobile(isOpen));
  };

  function _renderLink(config: any) {
    function isActiveTab(pathName: string, currentTab: string) {
      return pathName.includes(currentTab) ? true : false;
    }
    const isActive = isActiveTab(
      pathName === '/' && config.path === URL_SIDE_BAR.MAP
        ? URL_SIDE_BAR.MAP
        : pathName,
      config.path
    );

    return (
      <div
        className={`${s.navItem} ${isActive && s.active}`}
        onClick={() => {
          handleActiveTabe(config.path);
          handleCloseSideBar(false);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleActiveTabe(config.path);
            handleCloseSideBar(false);
          }
        }}
        tabIndex={0}
      >
        <img
          className={s.icon}
          src={isActive ? config.iconActive : config.icon}
          alt={config.alt}
        />
        <span className={s.subText}>{config.text}</span>
      </div>
    );
  }

  function _renderNonAccessLink(config: any) {
    return null
    // return (
    //   <Tooltip
    //     overlayClassName="tooltipLink"
    //     placement="bottomLeft"
    //     title={`No access to ${config.text}`}
    //   >
    //     <div className={`page-action ${s.personaAccess}`}>
    //       <div className="page-action-icon">
    //         <img src={config.icon} alt={config.alt} />
    //       </div>
    //       <div className="page-action-text">{config.text}</div>
    //     </div>
    //   </Tooltip>
    // );
  }

  function _renderSidebarLink(config, idx) {
    const isPersonaAccess = isPersonaAccessible(config.path);
    return (
      <>
        {isPersonaAccess ? (
          <Link
            key={idx}
            to={getDefaultView(config.path, config.viewType, selectedPersonaViewUrl) || config.path}
            onClick={() => handleClickSidebar(config)}
          >
            {_renderLink(config)}
          </Link>
        ) : (
          <>{_renderNonAccessLink(config)}</>
        )}
      </>
    );
  }

  return (
    <>
      <ModalForwardPriceComponent />
      <Desktop>
        <div className={s.root} role='navigation'>
          {URL_NAV_BAR_CONFIG.map((config, idx) =>
            _renderSidebarLink(config, idx)
          )}

          {_renderRisk()}

          <div className={s.navItem}
            onClick={() => setIsShowFPModal()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {setIsShowFPModal();}
            }}
            tabIndex={0}
          >
            <img
              className={s.icon}
              src='/img/icon/Farword curve.svg'
              alt='Notifications'
            />
            <span className={s.subText}>Forward Price</span>
          </div>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://confluence.petronas.com/display/GoDashboard/GO+Dashboard+Home'
          >
            <div className={s.navItem}>
              <img
                className={s.icon}
                src='/img/icon/confluence.svg'
                alt='confluence'
              />
              <span className={s.subText}>Reference</span>
            </div>
          </a>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://forms.office.com/r/5z454TynY3'
          >
            <div className={s.navItem}>
              <img
                className={s.icon}
                src='/img/icon/feedback.svg'
                alt='feedback'
              />
              <span className={s.subText}>Feedback</span>
            </div>
          </a>

          {isUserAdmin() && _renderPopUserManagement()}

          {_renderProfileBtn()}
        </div>
      </Desktop>
      {/* <Mobile>{_renderNavigationMobileTablet()}</Mobile> */}
      {/* <Tablet>{_renderNavigationMobileTablet()}</Tablet> */}
    </>
  );
}

export default NavigationComponent;
