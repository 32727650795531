import React, { useEffect, useState } from 'react'
import s from './UploadFileTable.module.css'
import { Modal, Table, TableProps, notification } from "antd";
import type { SorterResult } from 'antd/es/table/interface';
import { DataType } from '../../../container/admin/ScorecardManagement/ScorecardManagement';
import { useDispatch, useSelector } from 'react-redux';
import { clearPreviewUploadFile, clearUploadFileMessage, deleteUploadFile, downloadUploadFile, previewUploadFile } from '../../../action/actionScoreCard';
import parse from 'html-react-parser';

interface Iprop {
    data: DataType[];
    setSortedInfo: (value: SorterResult<DataType>) => void;
    sortedInfo: SorterResult<DataType>
}

const UploadFileTable = ({ data, setSortedInfo, sortedInfo }: Iprop) => {

    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowPreviewModal, setIsShowPreviewModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState({
        folderName: '',
        fileName: '',
        username: '',
        id: ''
    });
    // const [excelSrc, setExcelSrc] = useState('');
    const { deleteFileUploadMessage, isDeleteFileUpload, excelUrl } = useSelector((state: any) => state.scoreCard);
    const dispatch = useDispatch()
    const onPreview = (_text: any) => {
        const payload = {
            fileName: _text.fileName,
            folderName: _text.location,
            username: _text.uploadedBy,
        }
        dispatch(previewUploadFile(payload))
    }


    const onDownload = (_text: any) => {
        const payload = {
            fileName: _text.fileName,
            folderName: _text.location,
            username: _text.uploadedBy,
        }
        dispatch(downloadUploadFile(payload))
    }

    const onDelete = (_text: any) => {
        const payload = {
            fileName: _text.fileName,
            folderName: _text.location,
            username: _text.uploadedBy,
            id: _text.id,
            param: '?fileID=' + _text.id
        }
        setSelectedFile(payload)
        setIsShowModal(true)
    }

    const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<DataType>);
    };

    function renderTableColumn() {
        return [
            {
                title: "File Name",
                dataIndex: "fileName",
                key: 'fileName',
                width: 500,
                ellipsis: true,
                sorter: (a, b) => a.fileName.localeCompare(b.fileName),
                sortOrder: sortedInfo.columnKey === 'fileName' ? sortedInfo.order : null,
            },
            {
                title: "Location",
                dataIndex: "location",
                key: 'location',
                width: 150,
                ellipsis: true,
                sorter: (a, b) => a.location.localeCompare(b.location),
                sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null,
            },
            {
                title: "Upload by",
                dataIndex: "uploadedBy",
                key: 'uploadedBy',
                ellipsis: true,
                width: 170,
                sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy),
                sortOrder: sortedInfo.columnKey === 'uploadedBy' ? sortedInfo.order : null,
            },
            {
                title: "Status",
                dataIndex: "status",
                key: 'status',
                ellipsis: true,
                width: 130,
                render: (text, record) => {
                    let statusClass = ''; // Initialize statusClass with an empty string

                    switch (record.status.toUpperCase()) {
                        case "SUCCESS":
                            statusClass = s.successStatus; // Apply success styles
                            break;
                        case "PROCESSING":
                            statusClass = s.processingStatus; // Apply processing styles
                            break;
                        case "ERROR":
                            statusClass = s.errorStatus; // Apply error styles
                            break;
                        default:
                            break;
                    }

                    return (
                        <div className={s.statusContainer}>
                            <div className={statusClass}>
                                <span className={s.statusStyle}>{text.toUpperCase()}</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: "",
                ellipsis: true,
                width: 120,
                render: (_text, record) =>
                    <div className={s.actionButton} >
                        <img
                            src="/img/icon/Eye.svg"
                            alt="view"
                            onClick={() => onPreview(_text)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {onPreview(_text);}
                            }}
                            tabIndex={0}
                        />
                        <img
                            src="/img/icon/Download.svg"
                            alt="download"
                            onClick={() => onDownload(_text)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {onDownload(_text);}
                            }}
                            tabIndex={0}
                        />
                        <img
                            src="/img/icon/scorecard-delete.svg"
                            alt="delete"
                            onClick={() => onDelete(_text)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {onDelete(_text);}
                            }}
                            tabIndex={0}
                        />
                    </div>,
            },

        ]
    }

    const handleConfirmDelete = () => {
        dispatch(deleteUploadFile(selectedFile))
        setIsShowModal(false)
    }

    useEffect(() => {
        if (isDeleteFileUpload) {
            notification.open({
                message: 'Successfully deleted',
                description: (<><span>{parse(deleteFileUploadMessage)}</span></>),
                duration: 3,
                className: 'gotoast',
                icon: <img src="/img/icon/Check - Filled.svg" alt="close" />,
            });
            dispatch(clearUploadFileMessage())
        }
    }, [isDeleteFileUpload])

    useEffect(() => {
        if (excelUrl) {
            const viewSrc = "https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(excelUrl)
            window.open(viewSrc, '_blank');
            dispatch(clearPreviewUploadFile())
        }
    }, [excelUrl])



    return (
        <>
            <div className={s.uploadedFilesTableContainer}>
                <h4>Uploaded Files</h4>
            </div>
            <Table
                className="user-table-page"
                rowClassName={(_record, index) => index % 2 === 0 ? '' : s.tableStripe}
                columns={renderTableColumn()}
                dataSource={data}
                pagination={{
                    pageSize: 4,
                    position: ["bottomLeft"],
                    style: { marginTop: 10 },
                    // total: userTableData.length,
                    showTotal: (total, range) => <div className={s.totalPagination}><b>{range[0]}-{range[1]}</b>{` of ${total} items`}</div>,
                    showSizeChanger: false,
                }}
                scroll={{ x: 1132 }}
                onChange={handleChange}
            />
            <Modal
                className="delUserConfirmModalComponent"
                closable={false}
                visible={isShowModal}
                footer={null}
                width={320}
                style={{ top: 250 }}
            >
                <div className={s.modalDelContent}>
                    <div className={s.modalDelIcon}>
                        <img src="/img/icon/Info.svg" alt="arrow" />
                    </div>
                    <div className={s.modalDelHeader}>
                        <span>Are you sure you want to
                            Delete file "{selectedFile.fileName}" ?
                        </span>
                    </div>
                    <div className={s.modalDelSubtxt}>
                        <span>You can't undo this action.</span>
                    </div>
                    <div className={s.modalDelButton}>
                        <button className={s.modalDelFooterCancelButton} onClick={() => { setIsShowModal(false) }} >Cancel</button>
                        <button className={s.modalDelFooterDelButton} onClick={() => handleConfirmDelete()} >Delete</button>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export default UploadFileTable